@font-face {
  font-family: 'nanum_sincerity';
  src: url(./assets/fonts/nanum.ttf) format('woff');
  font-weight: normal;
  font-style: normal;
}
body, button {
  text-align: center;
  font-size: 16px;
  font-family: 'nanum_sincerity' !important;
}
